import React from 'react'



class Settings extends React.Component{


    render(){
        return(
            <h1 className="mt-20">v2</h1>
        )
    }
}




export default Settings